import {defineStore} from "pinia";
import {ref, Ref, watch} from "vue";
import Flow, {EngineBackgroundImageKeys} from '@flow-builder/core/src/Flows/Flow.ts';
import SliderService from "../Services/SliderService.ts";
import FlowApiService from "../Services/Flow/FlowApiService.ts";
import {EngineContract, useEngineStore} from "./engines.ts";
import Slide, {CustomSlideEventTrigger} from "@flow-builder/core/src/Slides/Slide.ts";
import InputValidationService from "../Services/InputValidationService.ts";
import SessionStorageService from "../Services/SessionStorageService.ts";
import {useConsumerStore} from "./consumer.ts";
import {SlideLifecycleEventHandler} from "../Services/SlideEventHandler.ts";
import {BaseAction, SlideActionType, TrackingActionType} from "@flow-builder/core/src/Blocks/Core/Actions/SlideAction.ts";
import Block, {BlockJson} from "@flow-builder/core/src/Blocks/Core/Block.ts";
import {
    LogicOperationsCollection,
    SlideLifecycleEvent,
    SlideLifecycleEventType,
    SlideLifecycleInputCollection,
    SlideValueCollection
} from "@flow-builder/core/src/Blocks/Core/Events/SlideLifecycleEvents.ts";
import {dispatchGtmEvent, dispatchLegacyEvents} from "../Composables/dispatchCustomEvent.ts";
import {checkSlideForImages, preloadImage} from "../Composables/images.ts";
import { WatchdogV2 } from "../Services/WatchdogV2.ts";
import MathCommand from "@flow-builder/core/src/LogicOperations/Math/MathCommand.ts";
import { useLogicStore } from "./logic.ts";
import { TrustedFormService, TrustedFormStatus } from "../Services/TrustedFormService.ts";

export enum TransitionType {
    TemplateToTemplate = 'templateToTemplate',
    ScreenToScreen = 'screenToScreen',
    ScreenToTemplate = 'screenToTemplate',
    TemplateToScreen = 'templateToScreen',
}

export interface SlideUpdateEvent {
    type: SlideActionType,
    trigger?: BlockJson|Slide|Block|null,
    actions?: BaseAction[],
}

export const useFlowStore = defineStore('flows', () => {
    const flow: Ref<Flow|null> = ref(null);
    const sliderService: SliderService = new SliderService();
    const logicStore = useLogicStore();
    const slideEventHandler: SlideLifecycleEventHandler = new SlideLifecycleEventHandler(/*sliderService*/);
    const showLoading = ref(false);
    const flowKey: Ref<string | null> = ref(null);
    const engineUrl = (window.previewMode && import.meta.env.VITE_PREVIEW_ENGINE_API_URL)
        ? import.meta.env.VITE_PREVIEW_ENGINE_API_URL
        : import.meta.env.VITE_ENGINE_API_URL;
    const flowApiService = new FlowApiService(engineUrl, import.meta.env.VITE_ENGINE_API_VERSION);
    const engineStore = useEngineStore();
    const transitionType: Ref<TransitionType> = ref(TransitionType.ScreenToScreen);
    const currentSlideId: Ref<string | null> = ref(null);

    const slideUpdating: Ref<boolean> = ref(false);
    const slideTransitioning: Ref<boolean> = ref(false);
    const transitionTimeout = 1000;

    const inputValidationService: InputValidationService = new InputValidationService();
    const flowStylesUpdated: Ref<number> = ref(0);

    const flowBackgroundHasChanged: Ref<boolean> = ref(false);
    const preloadedImageUrls: Ref<string[]> = ref([]);
    const preloadingImages: Ref<boolean> = ref(false);
    // The maximum pause between slides attempting to preload any images needed for the next slide
    const maximumImagePreloadTime = 1000;

    const previousButtonHidden: Ref<boolean> = ref(false);
    const nextButtonHidden: Ref<boolean> = ref(false);

    const trustedFormService: TrustedFormService = TrustedFormService.getService();

    const previousSlideTriggers = [
        SlideActionType.PreviousSlide,
    ];

    const slideSubmitTriggers = [
        SlideActionType.NextSlide,
        SlideActionType.GoToSlide,
        SlideActionType.GoToBranch,
    ];

    const refreshSlideTriggers = [
        SlideActionType.RerenderSlide,
    ];

    /**
     * Construct a Slide Lifecycle event from a triggering Block / autoProgress / other trigger
     * @param updateEvent
     */
    const getLifecycleEvent = (updateEvent: SlideUpdateEvent): SlideLifecycleEvent|null => {
        if (!updateEvent?.type) return null;

        let lifecycleEventType = null;
        if (previousSlideTriggers.includes(updateEvent.type)) {
            lifecycleEventType = SlideLifecycleEventType.SLIDE_BACK;
        } else if (slideSubmitTriggers.includes(updateEvent.type)) {
            lifecycleEventType = SlideLifecycleEventType.SLIDE_SUBMIT;
        } else if (refreshSlideTriggers.includes(updateEvent.type)) {
            lifecycleEventType = SlideLifecycleEventType.SLIDE_REFRESH;
        }

        return lifecycleEventType
            ? { type: lifecycleEventType, trigger: updateEvent.trigger as BlockJson ?? null, actions: updateEvent.actions ?? [] }
            : null;
    }

    /**
     * Collect current input/output/payload data for conditional events
     * @param currentPayload
     */
    const getInputCollection = (currentPayload: SlideValueCollection): SlideLifecycleInputCollection => {
        return {
            engines: {
                inputs: engineStore.inputs,
                outputs: engineStore.getEngineOutputValues(),
            },
            payload: currentPayload,
        }
    }

    const getInputAndVariableCollection = (currentPayload: SlideValueCollection): LogicOperationsCollection => {
        return {
            ...getInputCollection(currentPayload),
            variables: logicStore.getVariableCollection(),
        }
    }

    const getTrackingData = (): any => {
        const params: URLSearchParams = new URLSearchParams(window.location.search)

        let fbp = '';
        document.cookie.split(';').forEach((c) => {
          const s = c.trim().split('=');

          if(s[0] === '_fbp') {
            fbp = s[1];
          }
        });

        return {
          aff: params.get('aff'),
          cam: params.get('cam'),
          fbclid: params.get('fbclid'),
          fbp: fbp
        };
    };

    /**
     * Main Update loop for Slides
     * @param payload
     * @param token
     * @param slideUpdateEvent
     * @param forceUpdate
     */
    const update = async (payload: GenericJson, token: string, slideUpdateEvent: SlideUpdateEvent, forceUpdate = false) => {
        if (slideUpdating.value) return null;
        slideUpdating.value = true;

        const lifecycleEvent = getLifecycleEvent(slideUpdateEvent);

        if (lifecycleEvent?.type === SlideLifecycleEventType.SLIDE_BACK && sliderService.currentSlide) {
            if (!slideTransitioning.value && !sliderService.currentSlide?.preventPreviousSlide) {
                slideEventHandler.trigger(
                    lifecycleEvent,
                    getInputCollection(payload as SlideValueCollection),
                    sliderService.currentSlide,
                );
            }

            if (!forceUpdate) {
                slideUpdating.value = false;
                return null;
            }
        }

        const engines = engineStore.engines;

        if (trustedFormService.status === TrustedFormStatus.Loaded) {
            const trustedFormUrl = trustedFormService.getTrustedFormUrl();
            if (trustedFormUrl)
                payload[trustedFormService.payloadKey] = trustedFormUrl;
        }

        const trackingData = getTrackingData();
        for(const [key, value] of Object.entries(trackingData)) {
            payload[key] = value;
        }

        Object.keys(payload).forEach(key => {
            Object.keys(engineStore.outputs).forEach(engineKey => {
                Object.keys(engineStore.outputs[engineKey]).forEach(outputKey => {
                    engines.forEach(engine => {
                        if(engine.outputRules[outputKey]) {
                            if(engine.outputRules[outputKey].includes(key)) {
                                // @ts-ignore
                                if(engineStore.outputs[engineKey][outputKey].affectedValues[key] !== payload[key])
                                    engineStore.outputs[engineKey][outputKey].loading = true;

                                // @ts-ignore
                                engineStore.outputs[engineKey][outputKey].affectedValues[key] = payload[key];
                            }
                        }
                    });
                })
            });

            // @ts-ignore
            updateEngineInputs(key, payload[key]);

            // @ts-ignore
            if (key === 'phone') payload['url_convert'] = window.location.href;
        });

        // Handle on-click tracking events
        if (!lifecycleEvent && slideUpdateEvent.actions?.length && TrackingActionType.includes(slideUpdateEvent.type)) {
            handleGtmEvents(slideUpdateEvent.actions);
            slideUpdating.value = false;
            return;
        }

        const inputsValid = inputValidationService.validateInputs(sliderService.currentSlide as Slide);
        if (!inputsValid) {
            slideUpdating.value = false;
            return null;
        }

        const response = await flowApiService.update(payload, token)
            .catch(responseError => {
                const errorEvent: SlideLifecycleEvent = {
                    type: SlideLifecycleEventType.SLIDE_ERROR,
                    trigger: responseError || new Error(`Response error updating slide ${currentSlideId.value ?? 'slide'}.`),
                    actions: [],
                }
                slideEventHandler.trigger(
                    errorEvent,
                    getInputCollection(payload as SlideValueCollection),
                    sliderService.currentSlide,
                );
                slideUpdating.value = false;
                throw responseError;
        });

        if (lifecycleEvent) {
            slideEventHandler.trigger(
                lifecycleEvent,
                getInputCollection(payload as SlideValueCollection),
                sliderService.currentSlide,
            );

            // Fire legacy and legacy-legacy GTM events....
            if (lifecycleEvent.type === SlideLifecycleEventType.SLIDE_SUBMIT && (sliderService.currentSlide?.customEvents?.length || sliderService.currentSlide?.event)) {
                dispatchLegacyEvents(sliderService.currentSlide, CustomSlideEventTrigger.SLIDE_SUBMIT);
            }
        }

        slideUpdating.value = false;

        return response;
    }

    const updateEngineInputs = (key: string, value: any) => {
        engineStore.engines.forEach((engine: EngineContract) => {
            const index = engine.inputs.indexOf(key);

            if (index !== -1)
                engineStore.inputs[engine.name] = {...engineStore.inputs[engine.name], [key]: value};
        })

        new SessionStorageService().engineInputs = engineStore.inputs;
    };

    const getSlideTemplate = (): Slide | null => {
        const templateId = sliderService.currentSlide?.template
        if (templateId) {
            const targetTemplate = sliderService.getTemplates().find(template => template.id === templateId);
            if (targetTemplate) return targetTemplate;
        }
        return null;
    }

    const setTransitionType = (type: TransitionType) => {
        transitionType.value = type;
    }

    const startRendererTransition = () => {
        slideTransitioning.value = true;
        setTimeout(() => {
            slideTransitioning.value = false;
        }, transitionTimeout);
    }

    const finishRendererTransition = () => {
        slideTransitioning.value = false;
        flowBackgroundHasChanged.value = false;
    }
    const handleFlowBackgroundStyleUpdate = () => {
        const currentTemplate = getSlideTemplate();
        if ((sliderService.currentSlide?.useDataSource && sliderService.currentSlide.dataSourceIdentifier)
            || (currentTemplate?.useDataSource && currentTemplate?.dataSourceIdentifier)) {
            const flowUpdateEngine = "lead";
            const backgroundUpdateKeys = Object.values(EngineBackgroundImageKeys);
            if (engineStore.outputs[flowUpdateEngine]) {
                backgroundUpdateKeys.forEach(key => {
                    if (key in engineStore.outputs[flowUpdateEngine]) {
                        if (key == sliderService.currentSlide?.dataSourceIdentifier) {
                            const newBackgroundValue = engineStore.outputs[flowUpdateEngine][key].value;
                            if (/^http/.test(newBackgroundValue)) {
                                flowBackgroundHasChanged.value = true;
                                flow.value?.update({
                                    flowBackground: {
                                        backgroundImage: `url('${newBackgroundValue}')`,
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                    }
                                });
                                flowStylesUpdated.value = 1 - flowStylesUpdated.value;
                            }
                            //TODO: background color - theme name or RGB?
                        }
                    }
                });
            }
        }
    }

    const checkImagesArePreloaded = async (maxTimeout = maximumImagePreloadTime) => {
        return new Promise(res => {
            let timer = 0;
            const checkPreloadFlag = setInterval(() => {
                if (timer > maxTimeout || !preloadingImages.value) {
                    clearInterval(checkPreloadFlag);
                    preloadingImages.value = false;
                    res(true);
                }
                else {
                    timer += 100;
                }
            }, 100);
        })
    }

    const updateGlobalStyleVariables = (): void => {
        previousButtonHidden.value = !!sliderService.currentSlide?.preventPreviousSlide;
        nextButtonHidden.value = !!sliderService.currentSlide?.preventNextSlide;
    }

    /**
     * Watch current slide for incoming images
     */
    watch(currentSlideId, async () => {
        handleFlowBackgroundStyleUpdate();
        const templateSlide = transitionType.value === TransitionType.ScreenToTemplate
            ? getSlideTemplate() ?? undefined
            : undefined;

        const blockImageUrls = checkSlideForImages(sliderService.currentSlide as Slide, preloadedImageUrls.value, templateSlide);

        if (blockImageUrls.length) {
            preloadingImages.value = true;
            const results = await Promise.all(blockImageUrls.map(url => preloadImage(url)));
            const failedLoads = !!results.filter(v => !v)?.length

            if (!failedLoads) preloadingImages.value = false;

            preloadedImageUrls.value = [...preloadedImageUrls.value, ...blockImageUrls];
        }
    });

    /**
     * Watch engine outputs for incoming images
     */
    watch(engineStore.outputs, async (newVal) => {
        if ('lead' in engineStore.outputs) {
            const newImageUrls: string[] = Object.entries(newVal.lead).reduce((acc, [keyName, output]) => {
                if (Object.values(EngineBackgroundImageKeys).includes(keyName as EngineBackgroundImageKeys)) {
                    if (!preloadedImageUrls.value.includes(output.value) && /^http/.test(output.value)) {
                        acc.push(output.value);
                    }
                }
                return acc;
            }, [] as string[]);

            if (newImageUrls.length) {
                preloadingImages.value = true;
                await Promise.all(newImageUrls.map(async url => preloadImage(url)));
                preloadingImages.value = false;

                preloadedImageUrls.value = [...preloadedImageUrls.value, ...newImageUrls];
            }
        }
    });

    const initializeWatchDog = async () => {
        const consumerStore = useConsumerStore();

        const watchdogV2Url = import.meta.env.VITE_WATCHDOG_TWO_SERVER_URL?.replace(/\/$/, "")
        const watchdogV2PersonalAccessToken = import.meta.env.VITE_WATCHDOG_TWO_PERSONAL_ACCESS_TOKEN

        if (watchdogV2Url && watchdogV2PersonalAccessToken && !window.previewMode) {
            let watchdogV2 = new WatchdogV2(watchdogV2Url, watchdogV2PersonalAccessToken, flowApiService, consumerStore.bearerToken);

            if (import.meta.env.VITE_ENVIRONMENT === 'local' && import.meta.env.VITE_EXPOSE_WATCHDOG_TWO_TO_WINDOW === 'true') {
                // @ts-ignore
                window.watchdogV2 = watchdogV2
            }

            await watchdogV2.init()
        }
    }

    const fireSlideLoadedEvent = (slide: Slide) => {
        const slideLoadedEvent: SlideLifecycleEvent = {
            type: SlideLifecycleEventType.SLIDE_LOAD,
            trigger: slide,
            actions: [],
        }

        slideEventHandler.trigger(
            slideLoadedEvent,
            getInputCollection({}),
            slide,
        );

        // Fire legacy and legacy-legacy GTM events.... very loud inward groan
        if (sliderService.currentSlide?.customEvents?.length || sliderService.currentSlide?.event) {
            dispatchLegacyEvents(sliderService.currentSlide, CustomSlideEventTrigger.SLIDE_LOAD);
        }
    }

    const handleGtmEvents = (actions: BaseAction[]) => {
        actions.forEach(action => {
            if (TrackingActionType.includes(action.type))
                dispatchGtmEvent(action);
        });
    }

    const getMathCommands = (): MathCommand[] => {
        //@ts-ignore
        return flow.value?.getMathCommands().map(commandJson => MathCommand.fromJSON(commandJson)) ?? [];
    }

    return {
        flow,
        sliderService,
        showLoading,
        flowKey,
        flowApiService,
        transitionType,
        currentSlideId,
        flowStylesUpdated,
        slideEventHandler,
        flowBackgroundHasChanged,
        previousButtonHidden,
        nextButtonHidden,

        update,
        getSlideTemplate,
        setTransitionType,
        updateEngineInputs,
        startRendererTransition,
        finishRendererTransition,
        checkImagesArePreloaded,
        initializeWatchDog,
        fireSlideLoadedEvent,
        getInputCollection,
        updateGlobalStyleVariables,
        getMathCommands,
        getInputAndVariableCollection,
    };
});
