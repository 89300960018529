import {defineStore} from "pinia";
import {Ref, ref} from "vue";
import {default as engineService} from "../Services/Engines/EngineInteraction.ts";
import {EngineValueCollection} from "@flow-builder/core/src/Blocks/Core/Events/SlideLifecycleEvents.ts";

export interface EngineOutputs {
    [key: string]: {[key: string]: EngineValue}
}

export interface EngineInputs {
    [key: string]: {[key: string]: any}
}

export interface EngineValue {
    value: any;
    loading: boolean;
    affectedValues?: Object
}

export interface PictureOutput {
    id: string,
    name: string,
    picture: string
}

export interface EngineContract {
    name: string;
    inputs: string[];
    outputs: string[];
    outputRules: {[key: string]: string[]}
}

export const useEngineStore = defineStore('engines', () => {
    const service = ref(engineService);
    const outputs: Ref<EngineOutputs> = ref({});
    const inputs: Ref<EngineInputs> = ref({});
    const engines: Ref<EngineContract[]> = ref([]);
    const lockedOutputs: Ref<{ [engine: string]: string[] }> = ref({});

    const getEngineInputByKey = (key: string): any => {
        let value = null;

        engines.value.forEach((engine: EngineContract) => {
            const index = engine.inputs.indexOf(key);

            if (index !== -1)
                value = inputs.value[engine.name]?.[key] ?? null;
        })

        return value;
    }

    const getEngineOutputByEngineAndKey = (engine: string, key: string): any|null => {
        if (!outputs.value[engine]) return null;
        if (!outputs.value[engine][key]) return null;

        return outputs.value[engine][key]['value'] ?? null;
    }

    const getEngineOutputValues = () => {
        const output: EngineValueCollection = {};
        for (const engine in outputs.value) {
            output[engine] = {};
            Object.entries(outputs.value[engine]).forEach(([keyName, engineValues]) => {
                output[engine][keyName] = engineValues.value;
            });
        }

        return output;
    }

    const lockOutput = (engineName: string, outputName: string): void => {
        lockedOutputs.value[engineName] = lockedOutputs.value[engineName] ?? [];
        lockedOutputs.value[engineName].push(outputName);
    }

    const outputIsLocked = (engineName: string, outputName: string): boolean => {
        if (engineName in lockedOutputs.value) {
            return lockedOutputs.value[engineName].includes(outputName);
        }

        return false;
    }

    return {
        outputs,
        inputs,
        service,
        engines,
        getEngineInputByKey,
        getEngineOutputByEngineAndKey,
        getEngineOutputValues,
        lockOutput,
        outputIsLocked,
    }
})