import { useFlowStore } from "../Stores/flow.ts";
import { BlockJson } from "@flow-builder/core/src/Blocks/Core/Block.ts";
import Slide from "@flow-builder/core/src/Slides/Slide.ts";

export enum TrustedFormStatus {
    Disabled = -1,
    Inactive = 0,
    Ready = 1,
    Queued = 2,
    Loaded = 3,
    Completed = 10,
}

export class TrustedFormService {
    private static _instance: TrustedFormService;
    private readonly formId = 'trusted-form-tmp-data-holder';
    private readonly certSelector = '#xxTrustedFormCertUrl_0';
    public readonly payloadKey = 'trusted_form_url';
    private readonly triggerRegex = /^opt-in/;
    private isSolar: boolean | null = null;
    private _status: TrustedFormStatus = TrustedFormStatus.Ready;

    public static getService(): TrustedFormService {
        if (!this._instance)
            this._instance = new TrustedFormService();

        return this._instance;
    }

    public get status(): TrustedFormStatus {
        return this._status;
    }

    public async loadTrustedFormScript(): Promise<void> {
        if (this._status !== TrustedFormStatus.Queued)
            return;

        // Add temporary form so TrustedForm has somewhere to persist cert ID
        const tempFormId = this.formId;
        const form = document.createElement("form");
        form.id = tempFormId;
        document.getElementsByTagName('body')[0].appendChild(form);

        const tf = document.createElement('script');
        tf.type = 'text/javascript';
        tf.async = true;
        tf.src =
            ("https:" == document.location.protocol ? 'https' : 'http') +
            '://api.trustedform.com/trustedform.js?field=xxTrustedFormCertUrl&ping_field=xxTrustedFormPingUrl&l=' +
            new Date().getTime() + Math.random();

        const s = document.getElementsByTagName('script')[0];
        s.parentNode?.insertBefore(tf, s);

        this._status = TrustedFormStatus.Loaded;
    }

    public getTrustedFormUrl(): string | null {
        const tempFormSelector = `#${this.formId}`;
        const form = document.querySelector(tempFormSelector);
        const url = (form?.querySelector(this.certSelector) as HTMLInputElement)?.value ?? null;

        if (form) {
            form.remove();
            this._status = TrustedFormStatus.Completed;
        }

        return url;
    }

    public async shouldLoadScript(slide: Slide): Promise<boolean> {
        if (this.isSolar === null)
            this.checkEngines();

        if (this.isSolar === true || this._status !== TrustedFormStatus.Ready)
            return false;

        const scanBlocks = (blocks: BlockJson[]) => {
            for (const block of blocks) {
                if (this._status !== TrustedFormStatus.Ready)
                    break;

                if (block.blocks?.length)
                    scanBlocks(block.blocks);
                else {
                    if (this.triggerRegex.test(block.type)) {
                        this._status = TrustedFormStatus.Queued;
                        break;
                    }
                }
            }
        }
        //@ts-ignore
        scanBlocks(slide.hierarchy);

        return this.status === TrustedFormStatus.Queued;
    }

    private checkEngines(): void {
        const flowStore = useFlowStore();
        for (const engine of flowStore.flow!.getEngines()) {
            if (/solar/i.test(engine.name)) {
                this.isSolar = true;
                this._status = TrustedFormStatus.Disabled;

                return;
            }
        }

        this._status = TrustedFormStatus.Ready;
        this.isSolar = false;
    }
}